<template>
  <div class="contract-file-modal">
    <sweet-modal ref="modal" title="작업리스트 변경" overlay-theme="dark">
      <template v-if="contract">
        <div class="contract-file-content">
          <div class="body4 main margin-bottom-40">기본으로 제공되는 Notion 작업리스트가 아닌 다른 툴로 프로젝트를 관리하시나요?<br>프로젝트 작업 링크를 변경해 의뢰인에게 공유해주세요.</div>
          <input type="text" v-model="link" @keyup="e => link = e.target.value">
        </div>
        <button class="button is-primary" @click="clickDone">완료</button>
      </template>
    </sweet-modal>
  </div>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue';
import PartnerAPIMixin from '../../mixins/PartnerAPIMixin';
export default {
  name: "ContractTaskListModal",
  components: {
    SweetModal
  },
  mixins: [
    PartnerAPIMixin
  ],
  props: {},
  data() {
    return {
      contract: undefined,
      link: ''
    }
  },
  methods: {
    open(contract) {
      this.contract = contract;
      this.link = contract.task_list;
      this.$refs.modal.open();
    },
    clickDone() {
      if(!this.link) {
        this.toast('작업리스트 링크를 입력해주세요.');
        return;
      }
      this.$store.commit('setLoading', true);
      this.request.partner.patch(`launchpack/contract/${this.contract.id}`, { task_list: this.link, not_msg: true }).then(() => {
        this.$store.commit('setLoading', false);
        this.contract.task_list = this.link;
        this.toast('작업리스트 링크가 변경되었습니다.');
        this.$emit('done');
        this.$refs.modal.close();
      }).catch(() => {
        this.$store.commit('setLoading', false);
        this.toast('작업리스트 링크 변경에 실패했습니다.');
      });

    }
  }
}
</script>
<style lang="stylus" scoped>
.contract-file-content
  white-space pre-wrap
.button
  width 100%
  margin-top 40px
  font-size 14px
</style>
<style lang="stylus">
.contract-file-modal .sweet-modal .sweet-title
  border-bottom 0
</style>